import React, { Component } from 'react';
import Footer from '../Footer';
import Contact from '../Contact';
import Nav from '../Nav';
import { Container, Typography } from '@mui/material'
import '../SASS/Agile.scss'


class DesignThinking extends React.Component {

  render() {
    return (
      <div>
        <Nav />
        <Typography component={'div'} className='main_root'>
          <Container>

            <h1 className='agile-lv-title'>Design Thinking</h1>
            <p className='agile-lv-intro'>Design Thinking offers a human-centered approach to innovation and problem-solving. It brings empathy, collaboration, and creativity to the forefront, fostering solutions that resonate with users. However, implementing Design Thinking requires understanding, practice, and a specialized tool like DT-cube. we ensures you make the most of Design Thinking.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Coaching: Guiding Your Design Thinking Journey</p>
            <p className='agile-lv-intro'><strong>User-Centered Innovation:</strong> Our Design Thinking coaches, along with the DT-cube model, guide your teams in developing empathy-driven solutions.</p>
            <p className='agile-lv-intro'><strong>Ongoing Support:</strong> We provide continuous coaching, nurturing your Design Thinking culture.</p>
            <p className='agile-lv-intro'><strong>Customized Approach:</strong> Every organization is unique, and our coaching aligns with your specific goals.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Consulting: Strategic Design Thinking Solutions</p>
            <p className='agile-lv-intro'><strong>Empathetic Insights:</strong> Our consultants offer strategic guidance, leveraging the DT-cube model to foster user-centered innovation.</p>
            <p className='agile-lv-intro'><strong>Tailored Solutions:</strong> We craft bespoke Design Thinking strategies that resonate with your organization’s unique culture and goals.</p>
            <p className='agile-lv-intro'><strong>Holistic Approach:</strong> Our expertise, coupled with DT-cube, ensures a comprehensive approach to Design Thinking.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Training: Empowering Design Thinking Mastery</p>
            <p className='agile-lv-intro'><strong>Building Competencies:</strong> Our Design Thinking training programs, integrated with DT-cube, equip your teams with the tools and mindsets needed to innovate effectively.</p>
            <p className='agile-lv-intro'><strong>Hands-on Experience:</strong> Real-world scenarios and hands-on training help your teams internalize Design Thinking principles.</p>
            <p className='agile-lv-intro'><strong>Aligned with Your Needs:</strong> Our courses are designed to fit various roles within your organization, leveraging the power of DT-cube.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Your Partner in Design Thinking Exploration</p>
            <p className='agile-lv-intro'>Design Thinking is more than a process; it's a mindset. With our specialized model <strong><i>DT-cube</i></strong>, we offer a comprehensive approach to Design Thinking, ensuring creativity, empathy, and innovation.</p>
            <p className='agile-lv-intro'><strong>Flexible and User-Focused:</strong> We tailor our Design Thinking services to your needs, fostering solutions that resonate with users.</p>
            <p className='agile-lv-intro'><strong>Commitment to Innovation:</strong> Trust us to focus on your goals, implementing Design Thinking principles that foster continuous improvement.</p>

            <br></br>
            <p className='agile-lv-sub-title'>DT-cube</p>
            <p className='agile-lv-intro'>In a world where products and ideas evolve rapidly, it is crucial to have a robust framework that guides us towards creating successful and impactful solutions. DT-cube is the answer to that need, a unique approach</p>
            <p className='agile-lv-intro'>The DT-cube framework is represented as a 3D cube with the three dimensions—desirability, viability, and feasibility—forming its axes. Each product finds its unique position within this cube, reflecting its current stage of development. As products evolve and gather feedback from users and stakeholders, they traverse the cube, aiming to move from point 0 to point 1—a state of optimal balance where all three dimensions are maximized.</p>

            <br></br>
            <p className='agile-lv-close'><strong>Connect with us today to enable digital security.</strong></p>

            <div id='contactus'><br /><br /><Contact /></div>
            {/* <div><br /><br /><Footer /></div> */}
          </Container>
        </Typography>
        {/* <Typography component={'div'} className='main_root'>
          <Container>
            <h1 className='coaching-lv-title'>Coaching: Personalized Guidance for Your Agile Journey</h1>
            <p className='coaching-lv-intro'><strong>Unearthing Potential:</strong> At scaledv, our experienced Agile coaches work closely with your teams, identifying their unique strengths and areas for improvement. By tailoring our approach to your specific needs, we guide your teams in embracing Agile principles, unlocking their full potential.</p>
            <br></br>
            <p className='coaching-lv-intro'>Let us be your guide through the intricate maze of complexity and uncertainty. Our coaching services encompass leadership, team-based, and portfolio coaching, where we skillfully integrate both the unspoken, including psychological and emotional factors, with the known, to accelerate your journey towards success.</p>
          </Container>
        </Typography> */}
        {/* <Typography component={'div'} className='main_root'>
          <Container>
            <h1 className='coaching-lv-title'>Coaching</h1>
            <p className='coaching-lv-intro'>Through meaningful conversation, we aim to engage and collaborate with you, facilitating the discovery of effective solutions to the problem at hand.</p>
            <br></br>
            <p className='coaching-lv-intro'>Let us be your guide through the intricate maze of complexity and uncertainty. Our coaching services encompass leadership, team-based, and portfolio coaching, where we skillfully integrate both the unspoken, including psychological and emotional factors, with the known, to accelerate your journey towards success.</p>
          </Container>
        </Typography> */}
        {/* <div className='onebackground' ><br /><br /><Container>testing</Container></div>
        <div className='twobackground' ><br /><br /><DevOpsCoaching /></div>
        <div className='onebackground' ><br /><br /><DesignThinkingCoaching /></div> */}
        {/* <div id='whyus'><br/><br/><WhyUs/></div> */}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        {/* <div ><br /><br /><Contact /></div>
        <div><br /><br /><Footer /></div> */}
      </div >
    );
  }
}

export default DesignThinking;