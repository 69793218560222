import { Container } from '@mui/system';
import React, { Component, Fragment, } from 'react';
import Nav from './Nav'
import './SASS/Main.scss'
//import { Tooltip } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Box, ThemeProvider } from '@mui/system';
import './tooltip-trial'
import { styled } from '@mui/material/styles';
import { ReactComponent as approachstart } from './images/Services-approach/start.svg';
import Zoom from '@mui/material/Zoom';
import { ClassNames } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';



// class Consulting extends Component {

//   render() {
function Consulting() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: '40vw',
    },
  });
  return (
    <div className='ourservices'>
      <section className='coaching'>
        <Nav />
        <Container>
          <section className='s1'>
            <h1>Consulting</h1>
            <p>We hold a mirror and let you see the reality.</p>
          </section>
        </Container>
      </section>
      <section className='approach'>
        <Container sx={{ position: 'relative' }}>
          <h2>Approach</h2>
          <div className='divtag-main'>
            {isTabletOrMobile ? <section className='s2'>
              <span className='start'><CustomWidthTooltip placement="right" TransitionComponent={Zoom} id='v1' title={<h3 className='tooltip1'>We will be part of your system to know what is happening</h3>} enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Shadow</Box>
              </CustomWidthTooltip></span>
              <span className='start'><CustomWidthTooltip placement="right" id='v2' title={<h3 className='tooltip1'>Bring the observations to the table</h3>} enterTouchDelay={0} arrow>
                <span className='text'>Report</span>
              </CustomWidthTooltip></span>
              <span className='start'><CustomWidthTooltip placement="right" id='v3' title={<h3 className='tooltip1'>Where outside-in view meets inside view</h3>} enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Handshake</Box>
              </CustomWidthTooltip></span>
              <span className='start'><CustomWidthTooltip placement="right" id='v4' title={<h3 className='tooltip1'>A blueprint to address the gaps and reach your goal</h3>} enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Roadmap</Box>
              </CustomWidthTooltip></span>
            </section> :
              <section className='s2'>
                <span className='start'><Tooltip TransitionComponent={Zoom} id='v1' title={<h3 className='tooltip'>We will be part of your system to know what is happening</h3>} enterTouchDelay={0} arrow>
                  <Box component="span" className='text'>Shadow</Box>
                </Tooltip></span>
                <span className='start'><Tooltip id='v2' title={<h3 className='tooltip'>Bring the observations to the table</h3>} enterTouchDelay={0} arrow>
                  <span className='text'>Report</span>
                </Tooltip></span>
                <span className='start'><Tooltip id='v3' title={<h3 className='tooltip'>Where outside-in view meets inside view</h3>} enterTouchDelay={0} arrow>
                  <Box component="span" className='text'>Handshake</Box>
                </Tooltip></span>
                <span className='start'><Tooltip id='v4' title={<h3 className='tooltip'>A blueprint to address the gaps and reach your goal</h3>} enterTouchDelay={0} arrow>
                  <Box component="span" className='text'>Roadmap</Box>
                </Tooltip></span>
              </section>}
          </div>
        </Container >
      </section >
      <section className='footer'>
        <Container sx={{ position: 'absolute', bottom: '5vh', padding: '10px' }}>
          <div>
            <div className='footer1'>
              <span>We can help you with:</span>
              <span className='footer-span'>
                <span className='footer-span1'>Cyber Security</span>
                <span className='footer-span1'>DevOps</span>
                <span className='footer-span1'>Design Thinking</span>
                <span className='footer-span1'>SAFe</span>
                <span className='footer-span1'>Agile</span>
                <span className='footer-span1'>Lean</span>
              </span>
            </div>
          </div>
        </Container>
      </section>
    </div >
  );
}
// }

export default Consulting;