import React, { Component } from 'react';
import Footer from '../Footer';
import Contact from '../Contact';
import Nav from '../Nav';
import { Container, Typography } from '@mui/material'
import '../SASS/Agile.scss'
import DevOpsCoaching from '../Slider/DevOpsCoaching';
import AgileCoaching from '../Slider/AgileCoaching';
import DesignThinkingCoaching from '../Slider/DesignThinkingCoaching';
import { Block } from '@mui/icons-material';


class Lean extends React.Component {

  render() {
    return (
      <div>
        <Nav />
        <Typography component={'div'} className='main_root'>
          <Container>

            <h1 className='agile-lv-title'>Lean</h1>
            <p className='agile-lv-intro'>In the era of rapid innovation and heightened competition, Lean thinking offers a path to efficiency, customer focus, and continuous improvement. Implementing Lean principles can transform how your organization operates, but it requires understanding, practice, and expert guidance. This is where scaledv comes into play.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Coaching: Personalized Guidance for Your Agile Journey</p>
            <p className='agile-lv-intro'><strong>Streamlining Processes:</strong> Our Lean coaches work intimately with your teams to identify inefficiencies and create value-driven processes.</p>
            <p className='agile-lv-intro'><strong>Ongoing Support:</strong> Coaching is not a one-time event. We provide continuous support, nurturing your Lean culture.</p>
            <p className='agile-lv-intro'><strong>Customized Approach:</strong> Your business is unique, and our coaching aligns with your specific needs and goals.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Consulting: Strategic Lean Solutions</p>
            <p className='agile-lv-intro'><strong>Driving Efficiency:</strong> Our Lean consultants offer strategic insights, helping you create streamlined processes that resonate with customer needs.</p>
            <p className='agile-lv-intro'><strong>Tailored Solutions:</strong> We design custom Lean solutions that align with your organization’s unique culture and goals.</p>
            <p className='agile-lv-intro'><strong>Innovative Strategies:</strong> Our expertise enables us to craft innovative Lean strategies that drive growth and efficiency.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Training: Empowering Lean Mastery</p>
            <p className='agile-lv-intro'><strong>Building Lean Competencies:</strong> Our extensive training programs equip your teams with the tools and mindsets needed to implement Lean effectively.</p>
            <p className='agile-lv-intro'><strong>Hands-on Experience:</strong> Real-world scenarios and hands-on training help your teams internalize Lean principles.</p>
            <p className='agile-lv-intro'><strong>Adapted to Your Needs:</strong> Our courses are designed to fit different roles and levels within your organization.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Your Partner in Lean Transformation</p>
            <p className='agile-lv-intro'>Lean is more than a methodology; it's a business philosophy. We offer a comprehensive approach to Lean through coaching, consulting, and training, ensuring your success.</p>
            <p className='agile-lv-intro'><strong>Flexible and Responsive:</strong> We tailor our Lean services to your specific needs, ensuring alignment and value delivery.</p>
            <p className='agile-lv-intro'><strong>Commitment to Excellence:</strong> Trust us to focus on your goals, implementing Lean principles that foster continuous improvement.</p>

            <br></br>
            <p className='agile-lv-close'><strong>Connect with us today to drive value identification & maximization.</strong></p>

            <div id='contactus'><br /><br /><Contact /></div>
            {/* <div><br /><br /><Footer /></div> */}
          </Container>
        </Typography>
        {/* <Typography component={'div'} className='main_root'>
          <Container>
            <h1 className='coaching-lv-title'>Coaching: Personalized Guidance for Your Agile Journey</h1>
            <p className='coaching-lv-intro'><strong>Unearthing Potential:</strong> At scaledv, our experienced Agile coaches work closely with your teams, identifying their unique strengths and areas for improvement. By tailoring our approach to your specific needs, we guide your teams in embracing Agile principles, unlocking their full potential.</p>
            <br></br>
            <p className='coaching-lv-intro'>Let us be your guide through the intricate maze of complexity and uncertainty. Our coaching services encompass leadership, team-based, and portfolio coaching, where we skillfully integrate both the unspoken, including psychological and emotional factors, with the known, to accelerate your journey towards success.</p>
          </Container>
        </Typography> */}
        {/* <Typography component={'div'} className='main_root'>
          <Container>
            <h1 className='coaching-lv-title'>Coaching</h1>
            <p className='coaching-lv-intro'>Through meaningful conversation, we aim to engage and collaborate with you, facilitating the discovery of effective solutions to the problem at hand.</p>
            <br></br>
            <p className='coaching-lv-intro'>Let us be your guide through the intricate maze of complexity and uncertainty. Our coaching services encompass leadership, team-based, and portfolio coaching, where we skillfully integrate both the unspoken, including psychological and emotional factors, with the known, to accelerate your journey towards success.</p>
          </Container>
        </Typography> */}
        {/* <div className='onebackground' ><br /><br /><Container>testing</Container></div>
        <div className='twobackground' ><br /><br /><DevOpsCoaching /></div>
        <div className='onebackground' ><br /><br /><DesignThinkingCoaching /></div> */}
        {/* <div id='whyus'><br/><br/><WhyUs/></div> */}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        {/* <div ><br /><br /><Contact /></div>
        <div><br /><br /><Footer /></div> */}
      </div >
    );
  }
}

export default Lean;