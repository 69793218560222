import React, { Component, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import OurServices from '../Slider/OurServices';
import AreaOfExpertise from '../Slider/AreaOfExpertise';
import WhyUs from '../WhyUs';
import Contact from '../Contact'
import Navbar from '../Nav'
import Footer from '../Footer';

function withProps(Component: any): (props: any) => JSX.Element {
  return props => <Component {...props} navigate={useNavigate} />
}

class Home extends Component<{ navigate: any }> {
  render() {
    document.body.style.overflowY = "auto";
    document.body.style.overflowX = "hidden";
    document.body.style.backgroundColor = "#101728"
    return (
      <Fragment>
        <div className='main'>
          <Navbar />
          <Container sx={{ marginTop: '6%', borderTopWidth: "10px", border: '#101728' }}>
            <div className='home'>
              <div className="col-sm-7">
                <section className="course_list">
                  <span>we are a catalyst to your </span>
                  <div className='home-divtag'>
                    <span>[</span>
                    <span className='gradient-1'>
                      <Link to='/Agile'><span > Agile </span></Link><span>/</span>
                      <Link to='/SAFe'><span > SAFe </span></Link><span>/</span>
                      <Link to='/DevOps'><span > DevOps </span></Link><span>/</span><br />
                    </span>
                    <span className='gradient-1'>
                      <Link to='/Lean'><span > Lean </span></Link><span>/</span>
                      <Link to='/DesignThinking'><span className='span'> Design Thinking </span></Link><span>/</span><br />
                    </span>
                    <span className='gradient-1'>
                      <Link to='/CyberSecurity'><span > Cyber Security </span></Link>
                    </span>
                    <span> ] journey</span>
                  </div>
                </section>
              </div>

              <div className="col-sm-5">
                <section className="services">

                  <div className="button"><Link to='/coaching'><div className="button-1"><div className="service" id="button_1">COACHING </div></div></Link></div>
                  <div className="button"><Link to='/consulting'><div className="button-2"><div className="service" id="button_2">CONSULTING</div></div></Link></div>
                  <div className="button"><Link to='/training'><div className="button-3"><div className="service" id="button_3">TRAINING</div></div></Link></div>

                </section>
              </div>
            </div>

          </Container>
        </div>
        <div className='onebackground'><br /><br /><OurServices /></div>
        <div className='twobackground'><br /><br /><AreaOfExpertise /></div>
        <div><br /> <br /><WhyUs /></div>
        <div id='contactus'><br /><br /><Contact /></div>
        {/* <div><br /><br /><Footer /></div> */}
      </Fragment>
    );
  }
}

export default withProps(Home);