import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Styles } from './withStyles/ourservices'
import { withStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom'
import "./Css/style.css";
import "./Css/serviceStyle.scss";
import { Data } from './JSON/OurServices.JSON'

function Slider(props: any) {
  const { navigate }: any = useNavigate()
  let { classes }: any = props
  return (
    <>

      <Container>
        <div className={classes.root}>

          <h4 className='service-banner-text'>We design, train and guide your workforce
            alongside strategically derived next steps that’s
            aligned with your future-focused transformation goals.
          </h4>
        </div>
      </Container>
      {/* <Swiper
      effect={"coverflow"}
      grabCursor={true}
      loop={true}
      centeredSlides={true}
      slidesPerView={"auto"}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}

      navigation={true}
      modules={[Autoplay, Navigation, EffectCoverflow]}
      className="mySwiper"
    >
      <Typography component={'section'} sx={{marginBottom: '20%'}}>
        {Data.map((x: any) =>
          <SwiperSlide id='swiper'>
            <div className={classes.mainSlider}>
              <section><h2>{x.heading}</h2></section>
              <section className={classes.rootslide}>
                <Box key={x.id} component={'img'} src={x.logo}/>
                <div>{x.text}</div>
              </section>
              <br></br>
              <Link to={x.link} style={{color: 'white'}}
                    onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>{x.href}</Link>
            </div>
          </SwiperSlide>
        )}
      </Typography>
    </Swiper> */}
      {Data.map((x: any) =>
        <div className={x.id % 2 === 0 ? 'right-card-style' : 'left-card-style'}>
          <div className='card-image-container'>
            <Box key={x.id} component={'img'} src={x.logo} />
            <div className='card-text-container'>
              <h2>{x.heading}</h2>

              <div>{x.text}</div>
              {/* <Link to={x.link} style={{ color: 'white' }} id='home' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                {x.href}
              </Link> */}
            </div>
          </div>
          <br></br>

        </div>
      )}
    </>
  )
}

export default withStyles(Styles)(Slider)