import React, { Component } from 'react';
import Footer from '../Footer';
import Contact from '../Contact';
import Nav from '../Nav';
import { Container, Typography } from '@mui/material'
import '../SASS/Agile.scss'


class SAFe extends React.Component {

  render() {
    return (
      <div>
        <Nav />
        <Typography component={'div'} className='main_root'>
          <Container>

            <h1 className='agile-lv-title'>SAFe</h1>
            <p className='agile-lv-intro'>In a world where agility, adaptability, and alignment are key, the Scaled Agile Framework (SAFe) is not just a strategic choice; it's a business imperative. Implementing SAFe ensures that large-scale Agile projects are delivered with efficiency and cohesion. However, mastering SAFe is complex and requires dedicated guidance and expertise. This is where scaledv steps in.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Coaching: Your Navigator in the SAFe Landscape</p>
            <p className='agile-lv-intro'><strong>Unearthing Potential:</strong> Our experienced SAFe coaches work hand-in-hand with your organization. We identify unique opportunities and challenges, guiding you towards SAFe mastery.</p>
            <p className='agile-lv-intro'><strong>Bespoke Guidance:</strong> Bespoke Guidance: Every SAFe journey is different. At scaledv, we tailor our coaching to match your specific needs, nurturing a culture aligned with SAFe principles.</p>
            <p className='agile-lv-intro'><strong>Sustained Growth:</strong> Coaching is an ongoing process. We are committed to your success, providing continued support as you grow within the SAFe environment.</p>


            <br></br>
            <p className='agile-lv-sub-title'>Consulting: Strategic Solutions for SAFe Success</p>
            <p className='agile-lv-intro'><strong>Navigating Complexity:</strong> The path to SAFe is filled with intricacies. Our consultants provide strategic insights and industry best practices to steer your organization effectively.</p>
            <p className='agile-lv-intro'><strong>Custom Roadmaps:</strong> We understand the unique nature of your business. Our SAFe consultants develop personalized solutions to ensure a seamless transition to SAFe.</p>
            <p className='agile-lv-intro'><strong>Alignment and Efficiency:</strong> Through careful analysis and planning, we align your teams, ensuring optimal efficiency across the entire organization.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Training: Equipping for a SAFe Future</p>
            <p className='agile-lv-intro'><strong>Empowering Teams:</strong> Our comprehensive SAFe training programs provide the skills, mindset, and tools necessary for your organization to flourish within a SAFe framework.</p>
            <p className='agile-lv-intro'><strong>Versatility and Relevance:</strong> From SAFe Agilist to SAFe Practitioner, our training caters to various roles, staying aligned with industry standards and offering practical insights.</p>
            <p className='agile-lv-intro'><strong>Building Competence:</strong> We believe in hands-on experience. Our courses are designed to equip your teams with practical knowledge and real-world scenarios, preparing them for SAFe success.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Your Partner in SAFe Transformation</p>
            <p className='agile-lv-intro'> We see SAFe as more than a framework; it's a pathway to business excellence. With our comprehensive approach encompassing coaching, consulting, and training, we become your partner in this transformative journey.</p>
            <p className='agile-lv-intro'><strong>Tailored Support:</strong> Your organization's SAFe needs are unique. We offer flexible services tailored to meet these requirements, whether you are initiating your SAFe journey or optimizing existing processes.</p>
            <p className='agile-lv-intro'><strong>Commitment to Excellence:</strong> Our team of SAFe experts is committed to your success. We align our strategies with your vision, focusing on delivering value, collaboration, and continuous growth.</p>

            <br></br>
            <p className='agile-lv-close'><strong>Take the first step towards a successful SAFe journey with us. Contact us today, and let's build a more agile, responsive, and successful future for your organization.</strong></p>

            <div id='contactus'><br /><br /><Contact /></div>
            {/* <div><br /><br /><Footer /></div> */}
          </Container>
        </Typography>
        {/* <Typography component={'div'} className='main_root'>
          <Container>
            <h1 className='coaching-lv-title'>Coaching: Personalized Guidance for Your Agile Journey</h1>
            <p className='coaching-lv-intro'><strong>Unearthing Potential:</strong> At scaledv, our experienced Agile coaches work closely with your teams, identifying their unique strengths and areas for improvement. By tailoring our approach to your specific needs, we guide your teams in embracing Agile principles, unlocking their full potential.</p>
            <br></br>
            <p className='coaching-lv-intro'>Let us be your guide through the intricate maze of complexity and uncertainty. Our coaching services encompass leadership, team-based, and portfolio coaching, where we skillfully integrate both the unspoken, including psychological and emotional factors, with the known, to accelerate your journey towards success.</p>
          </Container>
        </Typography> */}
        {/* <Typography component={'div'} className='main_root'>
          <Container>
            <h1 className='coaching-lv-title'>Coaching</h1>
            <p className='coaching-lv-intro'>Through meaningful conversation, we aim to engage and collaborate with you, facilitating the discovery of effective solutions to the problem at hand.</p>
            <br></br>
            <p className='coaching-lv-intro'>Let us be your guide through the intricate maze of complexity and uncertainty. Our coaching services encompass leadership, team-based, and portfolio coaching, where we skillfully integrate both the unspoken, including psychological and emotional factors, with the known, to accelerate your journey towards success.</p>
          </Container>
        </Typography> */}
        {/* <div className='onebackground' ><br /><br /><Container>testing</Container></div>
        <div className='twobackground' ><br /><br /><DevOpsCoaching /></div>
        <div className='onebackground' ><br /><br /><DesignThinkingCoaching /></div> */}
        {/* <div id='whyus'><br/><br/><WhyUs/></div> */}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        {/* <div ><br /><br /><Contact /></div>
        <div><br /><br /><Footer /></div> */}
      </div >
    );
  }
}

export default SAFe; 