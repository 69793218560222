import React, { Component } from 'react';
import Footer from '../Footer';
import Contact from '../Contact';
import Nav from '../Nav';
import { Container, Typography } from '@mui/material'
import '../SASS/Agile.scss'


class CyberSecurity extends React.Component {

  render() {
    return (
      <div>
        <Nav />
        <Typography component={'div'} className='main_root'>
          <Container>

            <h1 className='agile-lv-title'>Cyber Security</h1>
            <p className='agile-lv-intro'>In our digitally connected world, Cyber Security is not just essential; it's critical. The threat landscape is constantly evolving, and safeguarding your organization requires constant vigilance, expertise, and strategic planning. That's where scaledv's Cyber Security solutions come into the picture.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Coaching: Strengthening Your Cyber Security Posture</p>
            <p className='agile-lv-intro'><strong>Risk Assessment:</strong> Our Cyber Security coaches identify vulnerabilities, working with your teams to build robust defenses.</p>
            <p className='agile-lv-intro'><strong>Continuous Guidance:</strong> We provide ongoing support, ensuring your Cyber Security measures stay up-to-date and effective.</p>
            <p className='agile-lv-intro'><strong>Tailored Strategy:</strong> Every organization has unique security needs. Our coaching aligns with these specific requirements.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Consulting: Strategic Cyber Security Solutions</p>
            <p className='agile-lv-intro'><strong>Comprehensive Protection:</strong> Our Cyber Security consultants offer a holistic approach, providing strategic insights to protect your digital assets.</p>
            <p className='agile-lv-intro'><strong>Custom Solutions:</strong> We analyze your unique needs and develop bespoke Cyber Security strategies.</p>
            <p className='agile-lv-intro'><strong>Innovation and Excellence:</strong> Our team provides cutting-edge solutions, ensuring your organization remains secure in a changing landscape.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Training: Building Cyber Security Skills</p>
            <p className='agile-lv-intro'><strong>Empowering Your Teams:</strong> Our Cyber Security training programs equip your staff with the knowledge and skills required to protect against threats.</p>
            <p className='agile-lv-intro'><strong>Practical Insights:</strong> Real-world scenarios and hands-on training ensure your teams are ready for actual Cyber Security challenges.</p>
            <p className='agile-lv-intro'><strong>Versatile Training:</strong> From basic awareness to advanced security measures, our courses cover all aspects of Cyber Security.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Your Partner in Cyber Security</p>
            <p className='agile-lv-intro'>We understand that Cyber Security is a continuous journey. Our comprehensive approach through coaching, consulting, and training ensures your organization's safety.</p>
            <p className='agile-lv-intro'><strong>Tailored Support:</strong> Our services are flexible and aligned with your specific Cyber Security needs.</p>
            <p className='agile-lv-intro'><strong>A Commitment to Protection:</strong> Trust us to focus on safeguarding your organization, ensuring robust defenses against evolving threats.</p>

            <br></br>
            <p className='agile-lv-close'><strong>Connect with us today to enable digital security.</strong></p>

            <div id='contactus'><br /><br /><Contact /></div>
            {/* <div><br /><br /><Footer /></div> */}
          </Container>
        </Typography>
        {/* <Typography component={'div'} className='main_root'>
          <Container>
            <h1 className='coaching-lv-title'>Coaching: Personalized Guidance for Your Agile Journey</h1>
            <p className='coaching-lv-intro'><strong>Unearthing Potential:</strong> At scaledv, our experienced Agile coaches work closely with your teams, identifying their unique strengths and areas for improvement. By tailoring our approach to your specific needs, we guide your teams in embracing Agile principles, unlocking their full potential.</p>
            <br></br>
            <p className='coaching-lv-intro'>Let us be your guide through the intricate maze of complexity and uncertainty. Our coaching services encompass leadership, team-based, and portfolio coaching, where we skillfully integrate both the unspoken, including psychological and emotional factors, with the known, to accelerate your journey towards success.</p>
          </Container>
        </Typography> */}
        {/* <Typography component={'div'} className='main_root'>
          <Container>
            <h1 className='coaching-lv-title'>Coaching</h1>
            <p className='coaching-lv-intro'>Through meaningful conversation, we aim to engage and collaborate with you, facilitating the discovery of effective solutions to the problem at hand.</p>
            <br></br>
            <p className='coaching-lv-intro'>Let us be your guide through the intricate maze of complexity and uncertainty. Our coaching services encompass leadership, team-based, and portfolio coaching, where we skillfully integrate both the unspoken, including psychological and emotional factors, with the known, to accelerate your journey towards success.</p>
          </Container>
        </Typography> */}
        {/* <div className='onebackground' ><br /><br /><Container>testing</Container></div>
        <div className='twobackground' ><br /><br /><DevOpsCoaching /></div>
        <div className='onebackground' ><br /><br /><DesignThinkingCoaching /></div> */}
        {/* <div id='whyus'><br/><br/><WhyUs/></div> */}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        {/* <div ><br /><br /><Contact /></div>
        <div><br /><br /><Footer /></div> */}
      </div >
    );
  }
}

export default CyberSecurity;