import { Container } from '@mui/material';
import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { Styles } from './WithStyles/whyus'

class WhyUs extends Component {
  render() {
    let { classes }: any = this.props
    return (
      <div className={classes.root}>
        <Container>
          <div className={classes.rootchild}>
            <section className={classes.grandchild_one}>
              <Container sx={{ marginTop: '-10%', borderTopWidth: "10px", border: '#101728', fontSize: '50px', fontWeight: '900', color: '#E863FD' }}> Why Us? </Container>
              <p>We are a boutique firm with a specialized team dedicated to value maximization. Our professionals focus on delivering personalized and expert services, ensuring that our clients achieve their unique goals.</p>
            </section>
            <section className={classes.grandchild_two}>
              <div className={classes.subchild1}></div>
              <div className={classes.subchild1}></div>
              <div className={classes.subchild1}></div>
              <div className={classes.subchild}><br></br>Minimalist</div>
              <div className={classes.subchild1}></div>
              <div className={classes.subchild1}></div>
              <div className={classes.subchild}><br></br>Pragmatic<br></br></div>
              <div className={classes.subchild1}></div>
              <div className={classes.subchild1}></div>
              <div className={classes.subchild}><br></br>Skilled<br></br></div>
              <div className={classes.subchild1}></div>
              <div className={classes.subchild1}></div>
              <div className={classes.subchild}><br></br>Misfits<br></br></div>
              <div></div>
            </section>
            <section className={classes.grandchild_three}>
              <p>We live by these priniciples</p>
            </section>
          </div>
        </Container >
      </div >
    );
  }
}

export default withStyles(Styles)(WhyUs);