import { Container } from '@mui/system';
import React, { Component, Fragment, } from 'react';
import Nav from './Nav'
import './SASS/Main.scss'
//import { Tooltip } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Box, ThemeProvider } from '@mui/system';
import './tooltip-trial'
import { styled } from '@mui/material/styles';
import { ReactComponent as approachstart } from './images/Services-approach/start.svg';
import Zoom from '@mui/material/Zoom';
import { ClassNames } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';


// class Coaching extends Component {

// render() {
function Coaching() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const isLandscapeOrPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' })

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: '40vw',
    },
  });

  const CustomWidthTooltip1 = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: '50vw',
    },
  });

  return (
    <div className='ourservices'>
      <section className='coaching'>
        <Nav />
        <Container>
          <section className='s1'>
            <h1>Coaching</h1>
            <p>Engage you in a meaningful conversation so that you find a solution to the problem.</p>
          </section>
        </Container>
      </section>
      <section className='approach'>
        <Container sx={{ position: 'relative' }}>
          <h2>Approach</h2>
          <div className='divtag-main'>
            {isTabletOrMobile && isLandscapeOrPortrait ? <section className='s2'>
              <span className='start'><CustomWidthTooltip placement="right" TransitionComponent={Zoom} id='v1' title={<h3 className='tooltip1'>Let us start with a learning of new perspective</h3>} enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Teach</Box>
              </CustomWidthTooltip></span>
              <span className='start'><CustomWidthTooltip placement="right" id='v2' title={<h3 className='tooltip1'>We hold hands till your actions mimic what you have learned</h3>} enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Mentor</Box>
              </CustomWidthTooltip></span>
              <span className='start'><CustomWidthTooltip placement="right" id='v3' title={<h3 className='tooltip1'>Now that you are on a path, we intervene only when necessary</h3>} enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Guide</Box>
              </CustomWidthTooltip></span>
              <span className='start'><CustomWidthTooltip placement="right" id='v4' title={<h3 className='tooltip1'>This is where we let you answer the problems by yourselves</h3>} enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Coach</Box>
              </CustomWidthTooltip></span>
              <span className='start'><CustomWidthTooltip placement="right" id='v5' title={<h3 className='tooltip1'>Before we leave, we ensure you understand how anti-patterns creep-in</h3>
              } enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Empower</Box>
              </CustomWidthTooltip ></span >
            </section > : isTabletOrMobile && isLandscape ? <section className='s2'>
              <span className='start'><CustomWidthTooltip1 TransitionComponent={Zoom} placement="bottom-end" id='v1' title={<h3 className='tooltip2'>Let us start with a learning of new perspective</h3>} enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Teach</Box>
              </CustomWidthTooltip1></span>
              <span className='start'><CustomWidthTooltip1 id='v2' title={<h3 className='tooltip2'>We hold hands till your actions mimic what you have learned</h3>} enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Mentor</Box>
              </CustomWidthTooltip1></span>
              <span className='start'><CustomWidthTooltip1 id='v3' title={<h3 className='tooltip2'>Now that you are on a path, we intervene only when necessary</h3>} enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Guide</Box>
              </CustomWidthTooltip1></span>
              <span className='start'><CustomWidthTooltip1 id='v4' title={<h3 className='tooltip2'>This is where we let you answer the problems by yourselves</h3>} enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Coach</Box>
              </CustomWidthTooltip1></span>
              <span className='start'><CustomWidthTooltip1 id='v5' title={<h3 className='tooltip2'>Before we leave, we ensure you understand how anti-patterns creep-in</h3>
              } enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Empower</Box>
              </CustomWidthTooltip1 ></span >
            </section > : <section className='s2'>
              <span className='start'><Tooltip TransitionComponent={Zoom} id='v1' title={<h3 className='tooltip'>Let us start with a learning of new perspective</h3>} enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Teach</Box>
              </Tooltip></span>
              <span className='start'><Tooltip id='v2' title={<h3 className='tooltip'>We hold hands till your actions mimic what you have learned</h3>} enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Mentor</Box>
              </Tooltip></span>
              <span className='start'><Tooltip id='v3' title={<h3 className='tooltip'>Now that you are on a path, we intervene only when necessary</h3>} enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Guide</Box>
              </Tooltip></span>
              <span className='start'><Tooltip id='v4' title={<h3 className='tooltip'>This is where we let you answer the problems by yourselves</h3>} enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Coach</Box>
              </Tooltip></span>
              <span className='start'><Tooltip id='v5' title={<h3 className='tooltip'>Before we leave, we ensure you understand how anti-patterns creep-in</h3>
              } enterTouchDelay={0} arrow>
                <Box component="span" className='text'>Empower</Box>
              </Tooltip ></span >
            </section >}
          </div >
        </Container >
      </section >
      <section className='footer'>
        <Container sx={{ position: 'absolute', bottom: '5vh', padding: '10px' }}>
          <div>
            <div className='footer1'>
              <span>We can help you with:</span>
              <span className='footer-span'>
                <span className='footer-span1'>Team</span>
                <span className='footer-span1'>Leadership</span>
                <span className='footer-span1'>Enterprise</span>
                <span className='footer-span1'>DevOps</span>
                <span className='footer-span1'>Design Thinking</span>
                <span className='footer-span1'>SAFe</span>
                <span className='footer-span1'>Agile</span>
                <span className='footer-span1'>Lean</span>
              </span>
            </div>
          </div>
        </Container>
      </section>
    </div >
  );
}
// }

export default Coaching;