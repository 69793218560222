import {Container} from '@mui/material';
import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import {ReactComponent as Logo} from './images/logos/logo.svg'
import {ReactComponent as Contact} from './images/contact-us/contactus.svg'
import './SASS/Nav.scss'

class Nav extends Component {
  render() {
    return (
      <nav>
        <Container>
          <div className='nav'>
            <section>
              <Link to='/' style={{textDecoration: 'none', color: 'white'}}><Logo className='svg'/>
              </Link></section>
            <section><Link to='/contact' style={{textDecoration: 'none', color: 'white'}}>
              <Contact className='contact'/>
            </Link></section>
          </div>
        </Container>
      </nav>
    );
  }
}

export default Nav;