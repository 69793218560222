import React, { Component } from 'react';
import Footer from '../Footer';
import Contact from '../Contact';
import Nav from '../Nav';
import { Container, Typography } from '@mui/material'
import '../SASS/Agile.scss'


class DevOps extends React.Component {

  render() {
    return (
      <div>
        <Nav />
        <Typography component={'div'} className='main_root'>
          <Container>

            <h1 className='agile-lv-title'>DevOps</h1>
            <p className='agile-lv-intro'> Integrating Development (Dev) and Operations (Ops) is crucial. DevOps fosters collaboration, improves efficiency, and speeds up delivery. Partner with scaledv to accelerate your DevOps journey.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Coaching: Cultivating a DevOps Culture</p>
            <p className='agile-lv-intro'><strong>Collaborative Growth:</strong> We facilitate communication between Dev and Ops, nurturing a culture of collaboration.</p>
            <p className='agile-lv-intro'><strong>Ongoing Relationship:</strong> Our coaches remain engaged, ensuring continued growth in DevOps practices.
            </p>

            <br></br>
            <p className='agile-lv-sub-title'>Consulting: Tailoring DevOps to Your Needs</p>
            <p className='agile-lv-intro'><strong>Strategic Guidance: </strong> We understand the challenges in implementing DevOps. Our consultants provide insights tailored to your organization.</p>
            <p className='agile-lv-intro'><strong>Seamless Integration:</strong> Customized solutions to ensure smooth adoption of DevOps principles.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Training: Equipping for DevOps Success</p>
            <p className='agile-lv-intro'><strong>Building Core Competencies:</strong> From continuous integration to continuous delivery, our training covers essential DevOps skills.</p>
            <p className='agile-lv-intro'><strong>Real-Life Scenarios: </strong> Practical, relevant training that prepares you for the actual challenges of DevOps.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Your Partner in  DevOps Implementation</p>
            <p className='agile-lv-intro'>DevOps is not just a set of tools; it's a shift in culture. With our expertise in coaching, consulting, and training, we at scaledv become your allies in this transformation.</p>
            <p className='agile-lv-intro'><strong>Personalized Support:</strong> Every organization has unique needs, and our DevOps services are crafted to meet them.</p>
            <p className='agile-lv-intro'><strong>Quality and Speed:</strong> We align our strategies with your goals, focusing on delivering quality products faster.</p>

            <p className='agile-lv-close'><strong>Connect with us and take the fast lane to DevOps success.</strong></p>

            <div id='contactus'><br /><br /><Contact /></div>
            {/* <div><br /><br /><Footer /></div> */}
          </Container>
        </Typography>
        {/* <Typogra phy component={'div'} className='main_root'>
      <Container>
            <h1 className='coaching-lv-title'>Coaching: Personalized Guidance for Your Agile Journey</h1>
            <p className='coaching-lv-intro'><strong>Unearthing Potential:</strong> At scaledv, our experienced Agile coaches work closely with your teams, identifying their unique strengths and areas for improvement. By tailoring our approach to your specific needs, we guide your teams in embracing Agile principles, unlocking their full potential.</p>
            <br></br>
            <p className='coaching-lv-intro'>Let us be your guide through the intricate maze of complexity and uncertainty. Our coaching services encompass leadership, team-based, and portfolio coaching, where we skillfully integrate both the unspoken, including psychological and emotional factors, with the known, to accelerate your journey towards success.</p>
          </Container>
        </Typography> */}
        {/* <Typography component={'div'} className='main_root'>
      <Container>
            <h1 className='coaching-lv-title'>Coaching</h1>
            <p className='coaching-lv-intro'>Through meaningful conversation, we aim to engage and collaborate with you, facilitating the discovery of effective solutions to the problem at hand.</p>
            <br></br>
            <p className='coaching-lv-intro'>Let us be your guide through the intricate maze of complexity and uncertainty. Our coaching services encompass leadership, team-based, and portfolio coaching, where we skillfully integrate both the unspoken, including psychological and emotional factors, with the known, to accelerate your journey towards success.</p>
          </Container>
        </Typography> */}
        {/* <div className='onebackground' ><br /><br /><Container>testing</Container></div>
    <div className='twobackground' ><br /><br /><DevOpsCoaching /></div>
        <div className='onebackground' ><br /><br /><DesignThinkingCoaching /></div> */}
        {/* <div id='whyus'><br/><br/><WhyUs/></div> */}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        {/* <div ><br /><br /><Contact /></div>
    <div><br /><br /><Footer /></div> */}
      </div >
    );
  }
}

export default DevOps;