export const Styles = {
  root: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    alignContent: 'left'
  },
  rootslide: {
    display: 'grid !important',
    gridTemplateColumns: "30% 60%",
    width: '100%',
    gridGap: '2%'
  },
  roottext: {
    fontFamily: 'Inter',
    fontWeight: '900',
    fontSize: '64px',
    width: '58%',
    lineHeight: '48px',
    justifyContent: 'left',
    alignItems: 'left',
    alignContent: 'left'
  },
  roottext1: {
    fontFamily: 'Inter',
    fontWeight: '900',
    fontSize: '64px',
    width: '58%',
    lineHeight: '48px',
    justifyContent: 'left',
    alignItems: 'left',
    alignContent: 'left'
  },
  roottext2: {
    fontFamily: 'Inter',
    fontWeight: '900',
    fontSize: '64px',
    width: '100%',
    lineHeight: '6vh'
  },
  slideText: {
    lineHeight: '33px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400'
  },
  mainSlider: {
    width: '100%',
    backgroundPosition: 'center',
    display: 'flex',
    color: '#101728',
    background: '#F5A561',
    padding: '20px',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column' as any,
    alignItems: 'center',
    backgroundSize: 'cover',
    boxShadow: '0 0 3px grey'
  },
  mainSlider2: {
    width: '100%',
    background: '#FF7998',
    backgroundPosition: 'center',
    display: 'flex',
    color: '#101728',
    padding: '20px',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column' as any,
    alignItems: 'center',
    backgroundSize: 'cover',
    boxShadow: '0 0 3px grey',
    "&:nth-child(1)": {}
  }
}