export const Data = [
  {
    id: "1",
    heading: "Cultural Alignment",
    // logo: require("../img/1.png"),
    text: 'DevOps is synonymous to System Admin these days. We want to change that. DevOps starts with cultural change and we drive this change by guiding teams and organizations adapt to this new way of working',
  },
  {
    id: "2",
    heading: "Structural Alignment",
    // logo: require("../img/1.png"),
    text: 'DevOps reaps benefits when you have the right members collaborate. This needs a change in the team structure. We work with you to ensure that these transitions are frictionless.'
  },
  {
    id: "3",
    heading: "Technical Alignment",
    // logo: require("../img/1.png"),
    text: 'Tools and techniques play an important role but that does not mean you need to introduces new ones or replace existing ones all over. Our focus is to maximize the outcome based on current tools and techniques before we recommend new ones'
  }

];