import React, { Component } from 'react';
import Footer from '../Footer';
import Contact from '../Contact';
import Nav from '../Nav';
import { Container, Typography } from '@mui/material'
import '../SASS/Agile.scss'
import DevOpsCoaching from '../Slider/DevOpsCoaching';
import AgileCoaching from '../Slider/AgileCoaching';
import DesignThinkingCoaching from '../Slider/DesignThinkingCoaching';
import { Block } from '@mui/icons-material';


class Agile extends React.Component {

  render() {
    return (
      <div>
        <Nav />
        <Typography component={'div'} className='main_root'>
          <Container>

            <h1 className='agile-lv-title' id='Agile'>Agile</h1>
            <p className='agile-lv-intro'>In today's rapidly evolving business landscape, Agile transformation is no longer a choice but a necessity. Embracing agility means fostering collaboration, enhancing efficiency, and staying responsive to market changes. However, Agile transformation is not an overnight process; it requires expertise, commitment, and continuous improvement. This is where scaledv comes in.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Coaching: Personalized Guidance for Your Agile Journey</p>
            <p className='agile-lv-intro'><strong>Unearthing Potential:</strong> At scaledv, our experienced Agile coaches work closely with your teams, identifying their unique strengths and areas for improvement. By tailoring our approach to your specific needs, we guide your teams in embracing Agile principles, unlocking their full potential.</p>
            <p className='agile-lv-intro'><strong>Sustained Growth:</strong> Coaching is more than a one-time event. It's an ongoing relationship. Our coaches are committed to your long-term success, providing the support and insights needed for sustained growth in the Agile environment.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Consulting: Strategic Insights for Agile Success</p>
            <p className='agile-lv-intro'><strong>Navigating Complexity:</strong> Agile transformation involves intricate organizational change. Our consultants offer strategic insights and best practices, steering your organization through the complexities of Agile implementation.</p>
            <p className='agile-lv-intro'><strong>Custom Solutions:</strong> Every organization is different, and so are the challenges in adopting Agile. Our consultants analyze your unique needs and develop bespoke solutions, ensuring a smooth and effective Agile transformation.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Training: Building Skills for an Agile Future</p>
            <p className='agile-lv-intro'><strong>Empowering Teams:</strong> Training is at the heart of Agile competence. Our comprehensive training programs are designed to equip your teams with the skills, mindset, and tools needed to thrive in an Agile environment.</p>
            <p className='agile-lv-intro'><strong>Versatility and Relevance:</strong> From Scrum Masters to Product Owners, our training covers various roles and levels. The courses are crafted to stay relevant to industry standards and trends, offering hands-on experience and real-world scenarios.</p>

            <br></br>
            <p className='agile-lv-sub-title'>Your Partner in Agile Transformation</p>
            <p className='agile-lv-intro'>At scaledv, we understand that Agile is more than a methodology; it's a culture. With our holistic approach encompassing coaching, consulting, and training, we become your partners in this transformative journey.</p>
            <p className='agile-lv-intro'><strong>Tailored Support:</strong> Your organization's needs are unique, and our services are flexible to meet them. Whether you're starting your Agile journey or looking to enhance your existing processes, scaledv is here to support you.</p>
            <p className='agile-lv-intro'><strong>Commitment to Excellence:</strong> Our team of experts in Agile, SAFe, and DevOps are committed to your success. We align our strategies with your goals, focusing on value, collaboration, and continuous improvement.</p>

            <p className='agile-lv-close'><strong>Connect with us today to take the first step or to improve maturity.</strong></p>

            <br></br>
            <div id='contactus'><br /><br /><Contact /></div>
            {/* <div><br /><br /><Footer /></div> */}
          </Container>
        </Typography>
        {/* <Typography component={'div'} className='main_root'>
          <Container>
            <h1 className='coaching-lv-title'>Coaching: Personalized Guidance for Your Agile Journey</h1>
            <p className='coaching-lv-intro'><strong>Unearthing Potential:</strong> At scaledv, our experienced Agile coaches work closely with your teams, identifying their unique strengths and areas for improvement. By tailoring our approach to your specific needs, we guide your teams in embracing Agile principles, unlocking their full potential.</p>
            <br></br>
            <p className='coaching-lv-intro'>Let us be your guide through the intricate maze of complexity and uncertainty. Our coaching services encompass leadership, team-based, and portfolio coaching, where we skillfully integrate both the unspoken, including psychological and emotional factors, with the known, to accelerate your journey towards success.</p>
          </Container>
        </Typography> */}
        {/* <Typography component={'div'} className='main_root'>
          <Container>
            <h1 className='coaching-lv-title'>Coaching</h1>
            <p className='coaching-lv-intro'>Through meaningful conversation, we aim to engage and collaborate with you, facilitating the discovery of effective solutions to the problem at hand.</p>
            <br></br>
            <p className='coaching-lv-intro'>Let us be your guide through the intricate maze of complexity and uncertainty. Our coaching services encompass leadership, team-based, and portfolio coaching, where we skillfully integrate both the unspoken, including psychological and emotional factors, with the known, to accelerate your journey towards success.</p>
          </Container>
        </Typography> */}
        {/* <div className='onebackground' ><br /><br /><Container>testing</Container></div>
        <div className='twobackground' ><br /><br /><DevOpsCoaching /></div>
        <div className='onebackground' ><br /><br /><DesignThinkingCoaching /></div> */}
        {/* <div id='whyus'><br/><br/><WhyUs/></div> */}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        {/* <div ><br /><br /><Contact /></div>
        <div><br /><br /><Footer /></div> */}
      </div >
    );
  }
}

export default Agile;