import { Button, Container, TextField, Typography } from '@mui/material';
import React, { Component, Fragment } from 'react';
import Nav from './Nav'
import './SASS/Contact.scss'

class Contact extends Component {

  render() {
    return (
      <Container>
        <Nav />
        <div className='contact-form'>
          <Typography sx={{ fontSize: '20px', fontWeight: '900', color: '#101728' }}> Either you are convinced that we are the right fit or you want to know more</Typography>
          {/* <h1 className='contact-heading'> or </h1> */}
          {/* <h1 className='contact-heading'> You want to know more</h1> */}
          <br></br>
          <Typography sx={{ fontSize: '20px', fontWeight: '900', color: 'palevioletred' }}> Either ways, please</Typography>
          <Typography sx={{ fontSize: '20px', fontWeight: '900', color: 'palevioletred' }}> write to us @ <a className='email-color' href="mailto: connect@scaledv.com">connect@scaledv.com</a></Typography>
          <Typography sx={{ fontSize: '20px', fontWeight: '900', color: 'palevioletred' }}>or</Typography>
          <Typography sx={{ fontSize: '20px', fontWeight: '900', color: 'palevioletred' }}>call / WhatsApp us @ + 91 9701 999 525</Typography>
        </div>
      </Container >
    );
  }
}

export default Contact;