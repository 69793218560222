import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '@mui/material'

import { ReactComponent as SVG } from './images/logos/logo.svg'
import { ReactComponent as Contact } from './images/contact-us/contactus.svg'
import './SASS/Home.scss'


class Alt extends Component {
  render() {
    document.body.style.overflow = "hidden";
    return (
      <Fragment>
        <div className='main'>
          <nav>
            <Container>
              <div className='nav'>
                <section><SVG className='svg' /></section>
                <section><Link to='/contact'><Contact className='contact' /></Link></section>
              </div>
            </Container>
          </nav>
          <Container>
            <div className='home'>
              <div>
                {/* areas of expertise */}
                <section className="course_list">
                  <span >Thank you for your interest. </span>
                  <span className='gradient-1'>However, long version is not available on mobile view</span>
                  <br></br>
                  <span className='gradient-1'>Kindly open scaledv.com on your laptop or desktop.</span>
                  <br></br>
                  <span >See you there!</span>
                </section>
              </div>
              {/* our services */}
            </div>
          </Container >
        </div >
      </Fragment >
    );
  }
}

export default Alt;     