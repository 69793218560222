import React, { Component } from 'react';
import Footer from '../Footer';
import Contact from '../Contact';
import Nav from '../Nav';
import Trainingslider from '../Slider/Trainingslider';
import { Container, Typography } from '@mui/material'
import '../SASS/Main.scss'

class Training extends Component {
  render() {
    return (
      <div>
        <Nav />

        <Typography component={'div'} className='main_root'>
          <Container>
            <h1 className='coaching-lv-title'>Training</h1>
            <p className='coaching-lv-intro'>Get the basics right!</p>
            <br></br>
            <p className='coaching-lv-intro'>A trainer imparts the know-how so that you and your teams have a common language to communicate, coordinate and collaborate.</p>
          </Container>
        </Typography>

        {/* <Typography component={'div'} className='main_root'>
          <Container>
            <Typography component={'div'}>
              <Typography component={'span'}>Training</Typography>
              <Typography component={'section'} className='section'>
                <Typography component={'div'}>Engage you in a meaningful conversation so that you find a solution to the
                  problem.</Typography>
                <Typography component={'div'}><img src={require('../Images/1.png')}/></Typography>
              </Typography>
              <Typography component={'section'} className='section_two'>
                <Typography component={'p'}>
                  Help you navigate through the maze of complexity and uncertainty. If the journey is obvious, would you
                  still need us? We conduct leadership, team-based, portfolio coaching but we integrate the unsaid
                  whether it is psychological, emotional factors to the known to ensure that your journey is accelerated
                </Typography>
              </Typography>
            </Typography>

          </Container>
        </Typography> */}
        <div className='onebackground'><br /><br /><Trainingslider /></div>
        <div id='contactus'><br /><br /><Contact /></div>
        {/* <div><br /><br /><Footer /></div> */}
      </div>
    );
  }
}

export default Training;