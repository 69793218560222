export const Data = [
  {
    id: "1",
    heading: "Agile",
    // logo: require("../img/1.png"),
    text: 'In today\'s software development landscape, agile serves as a cornerstone, enabling the delivery of exceptional results when implemented correctly. Our primary goal is to guide you in the proper implementation of agile concepts to help you maximize its benefits and achieve unparalleled outcomes.'
    , href: 'Learn More -->',
    link: '/Agile'
  },
  {
    id: "2",
    heading: "SAFe",
    // logo: require("../img/1.png"),
    text: 'SAFe offers a comprehensive agility-at-scale framework, integrating agile, lean, and system thinking. Navigating this framework effectively is crucial, as it involves numerous interconnected elements that must work in harmony to deliver intended results. Our expertise lies in orchestrating this complex system to ensure seamless synchronization and the successful delivery of value.'
    , href: 'Learn More -->',
    link: '/SAFe'
  },
  {
    id: "3",
    heading: "DevOps",
    // logo: require("../img/1.png"),
    text: 'DevOps is a collaborative model that effectively brings together Dev & Ops. It is coupled  with the appropriate tool stack. However, our vision extends beyond mere tool implementations. We strive to drive a profound cultural change that leverages your organizational structure to propel you towards your desired future state.',
    href: 'Learn More -->',
    link: '/DevOps'
  },
  {
    id: "4",
    heading: "Lean",
    // logo: require("../img/1.png"),
    text: 'We embrace Lean principles to drive efficiency and continuous improvement within your organization. Our approach is centered around identifying value and eliminating wasteful processes, optimizing resources, and streamlining workflows. By incorporating Lean methodologies seamlessly into your operations, we aim to foster a culture of ongoing improvement and deliver lasting value to your business.'
    , href: 'Learn More -->',
    link: '/Lean'
  },
  {
    id: "5",
    heading: "Design Thinking",
    // logo: require("../img/1.png"),
    text: 'Simplicity is our guiding principle - we design for needs. Our unique approach centers around user needs, enabling us to create solutions that truly resonate. We\'ve developed a powerful DVF matrix that leverages various design thinking tools to map your journey from the current vertices to your desired vertices. Let\'s embark on this transformative process together.'
    , href: 'Learn More -->',
    link: '/DesignThinking'
  },
  {
    id: "6",
    heading: "Cyber Security",
    // logo: require("../img/1.png"),
    text: 'Our focus is on what is essential to safeguard your business from any disruptions. Our comprehensive approach includes due diligence, assessments, tailored recommendations, and mitigation strategies seamlessly integrated into your day-to-day operations. We ensure that these measures never feel like an overhead but rather become an inherent part of your business\'s resilience.'
    , href: 'Learn More -->',
    link: '/CyberSecurity'
  }

];