import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { FormControlLabel, FormGroup, Switch, Container } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MenuProps } from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { Styles } from './WithStyles/Nav';
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as Hr } from '../ShortVersion-Components/images/logos/logo.svg'
import './SASS/Main.scss';

// class Nav extends Component {
export const Nav = (props: any): React.ReactElement => {
  const [serviceMenu, setServiceMenu] = React.useState<null | HTMLElement>(null);
  const [expertiseMenu, setExpertiseMenu] = React.useState<null | HTMLElement>(null);
  const serviceOpen = Boolean(serviceMenu);
  const expertiseOpen = Boolean(expertiseMenu);
  const navigate = useNavigate()
  const handleServiceClick = (event: React.MouseEvent<HTMLElement>) => {
    setServiceMenu(event.currentTarget);
    console.log(event, "ggggggggggg")
  };
  const handleExpertiseClick = (event: React.MouseEvent<HTMLElement>) => {
    setExpertiseMenu(event.currentTarget);
    console.log(event, "ggggggggggg")
  };
  const handleClose = () => {
    setServiceMenu(null);
    setExpertiseMenu(null);
  };

  const { classes }: any = props
  return (
    <>
      <div className={classes.background}>
        <Container>
          {/* <div className={classes.root}>
            <Link to='/'><span><Hr className={classes.svg}/></span></Link>
            <a href='#home' className={classes.span}><span>Home</span></a>
            <a href="#OurServices" className={classes.span}><span>OurServices</span></a>
            <a href='#AreasOfExpertise' className={classes.span}><span>AreasOfExpertise</span></a>
            <a href='#whyus' className={classes.span}><span>WhyUs</span></a>
            <a href='#contact' className={classes.span}><span>ContactUs</span></a>
          </div> */}
          <div className={classes.root}>
            <Link id={'home'} to='/'><span><Hr className={classes.svg} /></span></Link>
            <div>
              <Button
                id="fade-button"
                aria-controls={serviceOpen ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={serviceOpen ? 'true' : undefined}
                onClick={handleServiceClick}
                className={classes.span}
              >
                <span className='menuLinkText'>Offerings</span>
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={serviceMenu}
                open={serviceOpen}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem key={1} onClick={() => navigate('/coaching')}><span className='menuOptionText'>Coaching</span></MenuItem>
                <MenuItem key={2} onClick={() => navigate('/consulting')}><span className='menuOptionText'>Consulting</span></MenuItem>
                <MenuItem key={3} onClick={() => navigate('/training')}><span className='menuOptionText'>Training</span></MenuItem>
              </Menu>
            </div>
            <div>
              <Button
                id="fade-button"
                aria-controls={expertiseOpen ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={expertiseOpen ? 'true' : undefined}
                onClick={handleExpertiseClick}
                className={classes.span}
              >
                <span className='menuLinkText'>Expertise</span>
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={expertiseMenu}
                open={expertiseOpen}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem key={4} onClick={() => navigate('/Agile')}><span className='menuOptionText'>Agile</span></MenuItem>
                <MenuItem key={5} onClick={() => navigate('/SAFe')}><span className='menuOptionText'>SAFe</span></MenuItem>
                <MenuItem key={6} onClick={() => navigate('/DevOps')}><span className='menuOptionText'>DevOps</span></MenuItem>
                <MenuItem key={7} onClick={() => navigate('/Lean')}><span className='menuOptionText'>Lean</span></MenuItem>
                <MenuItem key={8} onClick={() => navigate('/DesignThinking')}><span className='menuOptionText'>Design Thinking</span></MenuItem>
                <MenuItem key={9} onClick={() => navigate('/CyberSecurity')}><span className='menuOptionText'>Cyber Security</span></MenuItem>
              </Menu>
            </div>
            {/* <a href='#home-WhyUs' className={classes.span}><span>WhyUs</span></a> */}
            <a href='#contactus' className={classes.span}><span>Contact Us</span></a>
          </div>
        </Container>
      </div >
    </>
  );
}

export default withStyles(Styles)(Nav);