export const Data = [
  {
    id: "1",
    heading: "Train",
    // logo: require("../img/1.png"),
    text: 'Know the aresenal at your disposal',
  },
  {
    id: "2",
    heading: "Simulate",
    // logo: require("../img/1.png"),
    text: 'Practice what you learned'
  },
  {
    id: "3",
    heading: "Execute",
    // logo: require("../img/1.png"),
    text: 'Solve the probelem at hand'
  }

];