import { Container } from '@mui/system';
import { Component, Fragment } from 'react';

import Nav from './Nav'
import './SASS/Agile.scss'

class CyberSecurity extends Component {
  state = {
    text1: "A must-have strategy in this deeply connected world.",
    que1: "Why do organizations need support with cyber security?",
    ans1: "A third party coverage of security will have a unbiased look into systems.",
    que2: "Why us?",
    ans2: "We know exactly -  available cybersecurity solutions to each client's specific needs and constraints, that can be a major advantage in your journey."
  }

  render() {
    const state: any = this.state
    return (
      <Fragment>
        <Nav />
        <div className='Agile'>

          <Container className='header'>
            <h1 className='title'>CyberSecurity</h1>
            <p className='para'>{state.text1}</p>
          </Container>
          <Container className='main-box'>

            <div className='box1'>
              <h3 className='que1'>{state.que1}</h3>
              <p className='ans1'>{state.ans1}</p>
            </div>
            <div className='box3'>
              <h3 className='que2'>{state.que2}</h3>
              <p className='ans2'>{state.ans2}</p>
            </div>


          </Container>

        </div>
      </Fragment>
    );
  }
}

export default CyberSecurity;