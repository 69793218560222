export const Styles = {
  root: {
    display: 'flex !important',
    justifyContent: 'space-between',
    width: 'auto',
    alignItems: 'center'
  },
  background: {
    backgroundColor: '#101728',
    position: 'fixed' as any,
    width: '100%',
    zIndex: '900',
    top: '0%',
    // backgroundSize: 'contain'
  },
  svg: {
    fill: '#FBF4E6',
    width: 'auto',
    height: '10vh',
    display: 'flex',
    // marginTop: '1px',
    '&:hover': {
      fill: 'grey'
    }
  },
  span: {
    fontFamily: 'Inter',
    display: 'flex',
    padding: '2px',
    fontStyle: 'normal',
    fontWeight: '700',
    marginRight: '2% !important',
    fontSize: '20px',
    lineHeight: '32px',
    textDecoration: 'none',
    color: 'white',
    "&:focus,&:hover": {
      backgroundColor: '#eee',
      color: '#101728'
    }
  },
  spantext: {
    marginTop: '2%',
  }
}