export const Data = [
  {
    id: "1",
    heading: "Coaching",
    // logo: require("../img/1.png"),
    text: 'Our multi-dimensional coaching services are designed to align individual contributions with a singular focus: creating value. Whether you\'re an executive, an individual contributor, part of a team, or an entire organization, our coaching will empower you to unlock your full potential, drive innovation, and achieve sustainable success.',
    href: 'Learn More -->',
    link: '/coaching'
  },
  {
    id: "2",
    heading: "Consulting",
    // logo: require("../img/1.png"),
    text: 'Our primary focus is answering one simple yet crucial question: What is the value? Through our analytical and insight-driven approach, we meticulously identify bottlenecks and provide tailored recommendations, along with key performance indicators, to gauge your progress and ensure your success.'
    , href: 'Learn More -->',
    link: '/consulting'
  },
  {
    id: "3",
    heading: "Training",
    // logo: require("../img/1.png"),
    text: 'Our mission is to equip you with a shared understanding, ensuring effective communication across the board. While we offer comprehensive framework-related training, what sets us apart is our unique approach of creating simulated environments that replicate real-time scenarios. This immersive learning experience allows you to maximize your learning potential and internalize the concepts effectively.'
    , href: 'Learn More -->',
    link: '/training'
  }

];