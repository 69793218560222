import { Container } from '@mui/material';
import React, { Component, Fragment } from 'react';

import Nav from './Nav'
import './SASS/Agile.scss'


interface IState {
  text1: string,
  que1: string,
  ans1: string,
  que2: string,
  ans2: string,
  display: any
}

class Agile extends Component<{}, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      text1: "Do it right and you are on the growth trajectory.",
      que1: "Agile movement is 20+ years, would you still need support?",
      ans1: "It is not about the agile maturity but our own beliefs that forces anti-patterns. So you need someone who can show you the true north.",
      que2: "Why us?",
      ans2: "We don't boss you around. We explain the reasons, benefits, and limitations of the framework so you can make the best choices for your transformation.",
      display: ""
    }
  }

  render() {
    const state: any = this.state
    return (
      <Fragment>
        <Nav />
        <div className='Agile'>

          <Container className='header'>
            <h1 className='title'>Agile</h1>
            <p className='para'>{state.text1}</p>
          </Container>
          <Container className='main-box'>

            <div className='box1'>
              <h3 className='que1'>{state.que1}</h3>
              <p className='ans1'>{state.ans1}</p>
            </div>
            <div className='box3'>
              <h3 className='que2'>{state.que2}</h3>
              <p className='ans2'>{state.ans2}</p>
            </div>


          </Container>

        </div>


      </Fragment>
    );
  }
}

export default Agile;