import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '@mui/material'

import { ReactComponent as SVG } from './images/logos/logo.svg'
import { ReactComponent as Contact } from './images/contact-us/contactus.svg'
import './SASS/Home.scss'


class Home extends Component {
  render() {
    document.body.style.overflow = "hidden";
    return (
      <Fragment>
        <div className='main'>
          <nav>
            <Container>
              <div className='nav'>
                <section><SVG className='svg' /></section>
                <section><Link to='/contact'><Contact className='contact' /></Link></section>
              </div>
            </Container>
          </nav>
          <Container>
            <div className='home'>
              <div>
                {/* areas of expertise */}
                <section className="course_list">
                  <span>we are a catalyst to your </span>
                  <div className='home-divtag'>
                    <span>[</span>
                    <span className='gradient-1'>
                      <Link id='navlink' to='/Agile'><span id='span'> Agile</span></Link><span> / </span>
                      <Link id='navlink' to='/SAFe'><span id='span'>SAFe</span></Link><span> / </span>
                      <Link id='navlink' to='/DevOps'><span id='span'>DevOps</span></Link><span> /</span><br />
                    </span>
                    <span className='gradient-1'>
                      <Link id='navlink' to='/Lean'><span id='span'>Lean</span></Link><span> / </span>
                      <Link id='navlink' to='/DesignThinking'><span id='span' className='span'>Design Thinking</span></Link><span> / </span><br />
                    </span>
                    <span className='gradient-1'>
                      <Link id='navlink' to='/CyberSecurity'><span id='span'>Cyber Security</span></Link>
                    </span>
                    <span> ] journey</span>
                  </div>
                </section>
              </div>
              {/* our services */}
              <div className="col-sm-5">
                <section className="services">

                  <div className="button"><Link to='/coaching'><div className="button-1"><div className="service" id="button_1">COACHING </div></div></Link></div>
                  <div className="button"><Link to='/consulting'><div className="button-2"><div className="service" id="button_2">CONSULTING</div></div></Link></div>
                  <div className="button"><Link to='/training'><div className="button-3"><div className="service" id="button_3">TRAINING</div></div></Link></div>

                </section>
              </div>
            </div>
          </Container >
        </div >
      </Fragment >
    );
  }
}

export default Home;     