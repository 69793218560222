import { Container } from '@mui/system';
import React, { Component, Fragment } from 'react';
import Nav from './Nav';
import './SASS/Agile.scss'

class Devops extends Component {
  state = {
    text1: "Drive transformation with integrating development and operations effort",
    que1: "Why do you need support with DevOps?",
    ans1: "DevOps is confusing because different organizations focus on different things like pipelines, automation, or team structure. To get it right, we need someone to lead and find the right balance between culture, practices, and collaboration.",
    que2: "Why us?",
    ans2: "We work with you to create the right reciepe for you, not just recommend a popular model that is existing."
  }

  render() {
    const state: any = this.state
    return (
      <Fragment>
        <Nav />
        <div className='Agile'>

          <Container className='header'>
            <h1 className='title'>DevOps</h1>
            <p className='para'>{state.text1}</p>
          </Container>
          <Container className='main-box'>

            <div className='box1'>
              <h3 className='que1'>{state.que1}</h3>
              <p className='ans1'>{state.ans1}</p>
            </div>
            <div className='box3'>
              <h3 className='que2'>{state.que2}</h3>
              <p className='ans2'>{state.ans2}</p>
            </div>


          </Container>
        </div>
      </Fragment>
    );
  }
}

export default Devops;