import { Container } from '@mui/system';
import React, { Component, Fragment } from 'react';
import Nav from './Nav'
import './SASS/Agile.scss'

class SAfe extends Component {
  state = {
    text1: "The world’s most trusted system for business agility.",
    que1: "Why do we need external help?",
    ans1: "SAFe® Implementation roadmap itself suggest organizations to take external help. Why? Because, to navigate through moving parts is a challenge by itself and more so if you are doing it for the first time.",
    que2: "Why us?",
    ans2: "We're more than just trainers and coaches; we're thought leaders who challenge limitations and create support systems to make things work."
  }

  render() {
    const state: any = this.state
    return (
      <Fragment>
        <Nav />
        <div className='Agile'>

          <Container className='header'>
            <h1 className='title'>SAFe</h1>
            <p className='para'>{state.text1}</p>
          </Container>
          <Container className='main-box'>

            <div className='box1'>
              <h3 className='que1'>{state.que1}</h3>
              <p className='ans1'>{state.ans1}</p>
            </div>
            <div className='box3'>
              <h3 className='que2'>{state.que2}</h3>
              <p className='ans2'>{state.ans2}</p>
            </div>


          </Container>

        </div>
      </Fragment>
    );
  }
}

export default SAfe;