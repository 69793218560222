export const Data = [
  {
    id: "1",
    heading: "Team Coaching",
    // logo: require("../img/1.png"),
    text: 'Agile teams are the basic building block of agile transformation. Our coaching embeds agile practices in teams day to day activities that it no longer becomes alien, but second-nature.',
  },
  {
    id: "2",
    heading: "Leadership Coaching",
    // logo: require("../img/1.png"),
    text: 'The major reason for agile failure is not lack of leadership support but their limited understanding of  how to support agile transformation. Our intent is to guide leaders successfully steer agile transformation.'
  },
  {
    id: "3",
    heading: "Enterprise Agility",
    // logo: require("../img/1.png"),
    text: 'Agile transformation might start with IT teams but do not end with them. For a successful transformation, all concerned functions of the organizations need to transform. Coaching focused on guiding not just technical teams, but portfolio, finance, HR & marketing teams.'
  }

];