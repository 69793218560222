import React, { Component } from 'react';
import Footer from '../Footer';
import Contact from '../Contact';
import ConsultingHeader from './ConsultingHeader';
import Nav from '../Nav';
import Consultingslider from '../Slider/Consultingslider';
import { Container, Typography } from '@mui/material'
import '../SASS/Main.scss'

class Consulting extends Component {
  render() {
    return (
      <div>
        <Nav />
        {/* <div id='Consulting'><ConsultingHeader /></div> */}
        <Typography component={'div'} className='main_root'>
          <Container>
            <h1 className='coaching-lv-title' id='Consulting'>Consulting</h1>
            <p className='coaching-lv-intro'>We hold a mirror and let you see the reality</p>
            <br></br>
            <p className='coaching-lv-intro'>Consulting for us is to let the truth out which you have to hear. We want to bring ourside-in view and show what is the gap between what you thought was going on and what might be the deviation and why did it happen.

              If only you are convinced with that, then lets talk next steps - Where you want to go from here?</p>
          </Container>
        </Typography>

        {/* <Typography component={'div'} className='main_root' >
          <Container>
            <Typography component={'div'}>
              <Typography component={'span'}>Consulting</Typography>
              <Typography component={'section'} className='section'>
                <Typography component={'div'}>Engage you in a meaningful conversation so that you find a solution to the
                  problem.</Typography>
                <Typography component={'div'}><img src={require('../Images/1.png')}/></Typography>
              </Typography>
              <Typography component={'section'} className='section_two'>
                <Typography component={'p'}>
                  Help you navigate through the maze of complexity and uncertainty. If the journey is obvious, would you
                  still need us? We conduct leadership, team-based, portfolio coaching but we integrate the unsaid
                  whether it is psychological, emotional factors to the known to ensure that your journey is accelerated
                </Typography>
              </Typography>
            </Typography>

          </Container>
        </Typography> */}
        <div className='onebackground' ><br /><br /><Consultingslider /></div>
        {/* <div id='whyus'><br/><br/><WhyUs/></div> */}
        <div id='contactus'><br /><br /><Contact /></div>
        {/* <div><br /><br /><Footer /></div> */}
      </div>
    );
  }
}

export default Consulting;