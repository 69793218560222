import React, { Component } from 'react';
import Footer from '../Footer';
import Contact from '../Contact';
import Nav from '../Nav';
import { Container, Typography } from '@mui/material'
import '../SASS/Coaching.scss'
import DevOpsCoaching from '../Slider/DevOpsCoaching';
import AgileCoaching from '../Slider/AgileCoaching';
import DesignThinkingCoaching from '../Slider/DesignThinkingCoaching';
import { Block } from '@mui/icons-material';



class Coaching extends React.Component {

  render() {
    return (
      <div>
        <Nav />
        <Typography component={'div'} className='main_root'>
          <Container>
            <h1 className='coaching-lv-title' id='Coaching'>Coaching</h1>
            <p className='coaching-lv-intro'>Through meaningful conversation, we aim to engage and collaborate with you, facilitating the discovery of effective solutions to the problem at hand.</p>
            <div></div>
            <p className='coaching-lv-intro'>Let us be your guide through the intricate maze of complexity and uncertainty. Our coaching services encompass leadership, team-based, and portfolio coaching, where we skillfully integrate both the unspoken, including psychological and emotional factors, with the known, to accelerate your journey towards success.</p>
            <br></br>
            <div></div>
          </Container>
        </Typography>
        <div className='onebackground' ><br /><br /><AgileCoaching /></div>
        <div className='twobackground' ><br /><br /><DevOpsCoaching /></div>
        <div className='onebackground' ><br /><br /><DesignThinkingCoaching /></div>
        {/* <div id='whyus'><br/><br/><WhyUs/></div> */}
        <div id='contactus'><br /><br /><Contact /></div>
        {/* <div><br /><br /><Footer /></div> */}
      </div >
    );
  }
}

export default Coaching;