import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Styles } from './withStyles/ourservices'
import { withStyles } from '@mui/styles';
import './Css/style.css'
import "./Css/areaOfExpertise.scss";
import { Data } from './JSON/AreaOfExpertise.JSON'
import { Link } from 'react-router-dom'

function Slider(props: any) {
  let { classes }: any = props
  return (
    <>

      {/* <Container>
        <div className={classes.root}>
          <h4 className={classes.roottext2}>We strive to provide our clients and collaborators with added value and
            benefits only in areas we have built expertise with experience and research.
          </h4>
        </div>
      </Container>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        loop={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}

        navigation={true}
        modules={[Autoplay, Navigation, EffectCoverflow]}
        className="mySwiper"
      >
        <Typography component={'section'} sx={{marginBottom: '20%'}}>
          {Data.map((x: any) =>
            <SwiperSlide>
              <div className={classes.mainSlider2}>
                <section><h2>{x.heading}</h2></section>
                <section className={classes.rootslide}>
                  <Box key={x.id} component={'img'} src={x.logo}/>
                  <div className={classes.slideText}>{x.text}</div>
                </section>
                <br></br>
                <Link to={x.link} style={{color: 'white'}}
                      onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>{x.href}</Link>
              </div>
            </SwiperSlide>
          )}
        </Typography>
      </Swiper> */}
      <Container>
        <div className={classes.root}>

          <h4 className='expertise-banner-text'>We strive to provide our clients and collaborators with added value and benefits only in areas we have built expertise with experience and research.
          </h4>
        </div>
      </Container>
      {Data.map((x: any) =>
        <div className={x.id % 2 === 0 ? 'expertise-right-card-style' : 'expertise-left-card-style'}>
          <div className='expertise-card-image-container'>
            <Box key={x.id} component={'img'} src={x.logo} />
            <div className='expertise-card-text-container'>
              <h2>{x.heading}</h2>

              <div>{x.text}</div>
              {/* <Link to={x.link} style={{ color: 'white' }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                {x.href}
              </Link> */}
            </div>
          </div>
          <br></br>

        </div>
      )}
    </>
  )
}

export default withStyles(Styles)(Slider)