import { Container } from '@mui/system';
import { Component, Fragment } from 'react';
import Nav from './Nav'
import './SASS/Agile.scss'

class DesignThinking extends Component {
  state = {
    text1: "Navigate through complexity and make your products desirable, viable and feasible",
    que1: "Why can't we drive Design thinking ourselves?",
    ans1: "Design thinking unlocks creativity and problem-solving skills by thinking outside the box. However, our cognitive biases can hold us back, so we need someone to help us focus on what's necessary, rather than what we assume we should do.",
    que2: "Why us?",
    ans2: "Our website should convice you that we think 'out of the box'."
  }

  render() {
    const state: any = this.state
    return (
      <Fragment>
        <Nav />
        <div className='Agile'>

          <Container className='header'>
            <h1 className='title'>DesignThinking</h1>
            <p className='para'>{state.text1}</p>
          </Container>
          <Container className='main-box'>

            <div className='box1'>
              <h3 className='que1'>{state.que1}</h3>
              <p className='ans1'>{state.ans1}</p>
            </div>
            <div className='box3'>
              <h3 className='que2'>{state.que2}</h3>
              <p className='ans2'>{state.ans2}</p>
            </div>


          </Container>

        </div>
      </Fragment>
    );
  }
}

export default DesignThinking;