import { height, padding } from "@mui/system";

export const Styles = {
  root: {
    backgroundColor: '#101728',
    color: '#FBF4E6',
    padding: '10vh',
    height: '100vh',
  },
  rootchild: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap' as any,
  },
  grandchild_one: {
    width: '50%',
    fontSize: '4vh',
    padding: '50px',
  },
  grandchild_two: {
    width: '50%',
    display: 'grid',
    gridTemplateColumns: '45% 45%',
    gap: '10%',
  },
  grandchild_three: {
    width: '50%',
    fontSize: '4vh',
    padding: '50px',
    marginTop: '-8%',
  },
  subchild: {
    border: '1px solid red',
    margin: '2px',
    // padding: '0px',
    textAlign: 'center' as any,
    background: '#FBF4E6',
    color: '#101728',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '4vh',
    lineHeight: '4vh',
    borderRadius: '1rem',
    height: '100px',
    justifyContent: 'center'
  },
  subchild1: {
    padding: '5vh',


  }
}