import { Container } from '@mui/system';
import React, { Component, Fragment } from 'react';
import Nav from './Nav'
import './SASS/Agile.scss'

class Lean extends Component {
  state = {
    text1: "Focus on how to streamline value to the customer",
    que1: "Is Lean relevant?",
    ans1: "Yes, very much! Lean is first principle approach to delivering value to your customers. All that it asks you to do is optimize your value stream",
    que2: "Why us?",
    ans2: "All that we do is focus on maximizing value to the customer. If our conversations make sense, all the credit goes to Lean mindset."
  }

  render() {
    const state: any = this.state
    return (
      <Fragment>
        <Nav />
        <div className='Agile'>

          <Container className='header'>
            <h1 className='title'>Lean</h1>
            <p className='para'>{state.text1}</p>
          </Container>
          <Container className='main-box'>

            <div className='box1'>
              <h3 className='que1'>{state.que1}</h3>
              <p className='ans1'>{state.ans1}</p>
            </div>
            <div className='box3'>
              <h3 className='que2'>{state.que2}</h3>
              <p className='ans2'>{state.ans2}</p>
            </div>


          </Container>

        </div>
      </Fragment>
    );
  }
}

export default Lean;