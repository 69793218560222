import React, { Component, Fragment, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom'
import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/material/styles';
import { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { BrowserView, MobileView } from 'react-device-detect';


import Home from './ShortVersion-Components/Home';
import Contact from './ShortVersion-Components/Contact'

import Agile from './ShortVersion-Components/Agile';
import SAFe from './ShortVersion-Components/SAFe';
import Devops from './ShortVersion-Components/Devops';
import Lean from './ShortVersion-Components/Lean';
import DesignThinking from './ShortVersion-Components/DesignThinking';
import CyberSecurity from './ShortVersion-Components/CyberSecurity';

import CONSULTING from './ShortVersion-Components/Consulting';
import TRAINING from './ShortVersion-Components/Training';
import Coaching from './ShortVersion-Components/Coaching';

import LongVersionHome from './Longversion-Components/Home/Home';

import LongVersionAgile from './Longversion-Components/Agile/Agile';
import LongVersionSAFe from './Longversion-Components/SAFe/SaFe';
import LongVersionDevops from './Longversion-Components/Devops/Devops';
import LongVersionLean from './Longversion-Components/Lean/Lean';
import LongVersionCyberSecurity from './Longversion-Components/CyberSecurity/CyberSecurity';
import LongVersionDesignThinking from './Longversion-Components/DesignThinking/DesignThinking';

import LongVersionCoaching from './Longversion-Components/Coaching/Coaching';
import LongVersionConsulting from './Longversion-Components/Consulting/Consulting';
import LongVersionTraining from './Longversion-Components/Traning/Training';
import { Container } from '@mui/system';
import Alt from './ShortVersion-Components/Alt';

// import LongVersionHome from './Longversion-Components/Home/Home1'

// const MaterialUISwitch = styled(Switch)(({ theme }) => ({
//   width: 62,
//   height: 34,
//   padding: 7,
//   '& .MuiSwitch-switchBase': {
//     margin: 1,
//     padding: 0,
//     transform: 'translateX(6px)',
//     '&.Mui-checked': {
//       color: '#fff',
//       transform: 'translateX(22px)',
//       '& .MuiSwitch-thumb:before': {
//         backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
//           '#fff',
//         )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
//       },
//       '& + .MuiSwitch-track': {
//         opacity: 1,
//         // backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
//         backgroundColor: theme.palette.mode === 'dark' ? '#E863FD' : '#E863FD',
//       },
//     },
//   },
//   '& .MuiSwitch-thumb': {
//     // backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
//     backgroundColor: theme.palette.mode === 'dark' ? '#FF5E84' : '#FF5E84',
//     width: 32,
//     height: 32,
//     '&:before': {
//       content: "''",
//       position: 'absolute',
//       width: '100%',
//       height: '100%',
//       left: 0,
//       top: 0,
//       backgroundRepeat: 'no-repeat',
//       backgroundPosition: 'center',
//       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
//         '#fff',
//       )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
//     },
//   },
//   '& .MuiSwitch-track': {
//     opacity: 1,
//     backgroundColor: theme.palette.mode === 'dark' ? '#E863FD' : '#E863FD',
//     borderRadius: 20 / 2,
//   },
// }));

export const App = (): React.ReactElement => {
  const [shortVersionValue, setShortVersionValue] = useState(true);
  // render() {
  return (
    <>
      <BrowserView>
        <Typography component={'div'}>
          <Routes>
            {shortVersionValue ? (
              <Fragment>
                <Route path='/' element={<Home />} />
                <Route path='/coaching' element={<Coaching />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/consulting' element={<CONSULTING />} />
                <Route path='/training' element={<TRAINING />} />
                <Route path='/Agile' element={<Agile />} />
                <Route path='/SAFe' element={<SAFe />} />
                <Route path='/DevOps' element={<Devops />} />
                <Route path='/Lean' element={<Lean />} />
                <Route path='/DesignThinking' element={<DesignThinking />} />
                <Route path='/CyberSecurity' element={<CyberSecurity />} />
              </Fragment>) : (
              <Fragment>
                <Route path='/contact' element={<Contact />} />
                <Route path='/' element={<LongVersionHome />} />
                <Route path='/coaching' element={<LongVersionCoaching />} />
                <Route path='/consulting' element={<LongVersionConsulting />} />
                <Route path='/training' element={<LongVersionTraining />} />
                <Route path='/Agile' element={<LongVersionAgile />} />
                <Route path='/SAFe' element={<LongVersionSAFe />} />
                <Route path='/DevOps' element={<LongVersionDevops />} />
                <Route path='/Lean' element={<LongVersionLean />} />
                <Route path='/DesignThinking' element={<LongVersionDesignThinking />} />
                <Route path='/CyberSecurity' element={<LongVersionCyberSecurity />} />
              </Fragment>)}
          </Routes>
          {/* <FormGroup sx={{ position: 'fixed', zIndex: '900', bottom: '10px', right: '0' }}>
        <FormControlLabel sx={{ fontSize: '24px', fontWeight: '900', color: '#fff' }}
          control={<MaterialUISwitch onChange={() => setShortVersionValue(!shortVersionValue)} checked={shortVersionValue} />}
          label="Switch"
        />
      </FormGroup> */}
          <FormGroup sx={{ position: 'fixed', zIndex: '900', bottom: '10px', right: '20px' }}>
            <Stack direction="row" spacing={1} alignItems="center">
              {/* <Typography sx={{ color: 'white', bgcolor: '#101728' }}>lv</Typography> */}
              <FormControlLabel
                control={<Switch sx={{ color: 'white', bgcolor: 'palevioletred', borderRadius: '1.5rem' }} onChange={() => setShortVersionValue(!shortVersionValue)} checked={shortVersionValue} />} label='' />
              {/* <Typography sx={{ color: 'white', bgcolor: '#101728' }}>sv</Typography> */}
            </Stack>
          </FormGroup>
        </Typography>
      </BrowserView>
      <MobileView>
        <Typography component={'div'}>
          <Routes>
            {shortVersionValue ? (
              <Fragment>
                <Route path='/' element={<Home />} />
                <Route path='/coaching' element={<Coaching />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/consulting' element={<CONSULTING />} />
                <Route path='/training' element={<TRAINING />} />
                <Route path='/Agile' element={<Agile />} />
                <Route path='/SAFe' element={<SAFe />} />
                <Route path='/DevOps' element={<Devops />} />
                <Route path='/Lean' element={<Lean />} />
                <Route path='/DesignThinking' element={<DesignThinking />} />
                <Route path='/CyberSecurity' element={<CyberSecurity />} />
              </Fragment>) : (
              <Fragment>
                <Route path='/' element={<Alt />} />
                <Route path='/coaching' element={<Alt />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/consulting' element={<Alt />} />
                <Route path='/training' element={<Alt />} />
                <Route path='/Agile' element={<Alt />} />
                <Route path='/SAFe' element={<Alt />} />
                <Route path='/DevOps' element={<Alt />} />
                <Route path='/Lean' element={<Alt />} />
                <Route path='/DesignThinking' element={<Alt />} />
                <Route path='/CyberSecurity' element={<Alt />} />
              </Fragment>
            )
            }
          </Routes>
          {/* <FormGroup sx={{ position: 'fixed', zIndex: '900', bottom: '10px', right: '0' }}>
        <FormControlLabel sx={{ fontSize: '24px', fontWeight: '900', color: '#fff' }}
          control={<MaterialUISwitch onChange={() => setShortVersionValue(!shortVersionValue)} checked={shortVersionValue} />}
          label="Switch"
        />
      </FormGroup> */}
          <FormGroup sx={{ position: 'fixed', zIndex: '900', bottom: '5px', right: '10px' }}>
            <Stack direction="row" spacing={1} alignItems="center">
              {/* <Typography sx={{ color: 'white', bgcolor: '#101728' }}>lv</Typography> */}
              <FormControlLabel
                control={<Switch sx={{ color: 'white', bgcolor: 'palevioletred', borderRadius: '1.5rem' }} onChange={() => setShortVersionValue(!shortVersionValue)} checked={shortVersionValue} />} label='' />
              {/* <Typography sx={{ color: 'white', bgcolor: '#101728' }}>sv</Typography> */}
            </Stack>
          </FormGroup>
        </Typography>
      </MobileView>

    </>
  );
  // }
}

export default App;
