export const Data = [
  {
    id: "1",
    heading: "Agile",
    // logo: require("../img/1.png"),
    text: 'How agile are you?',
  },
  {
    id: "2",
    heading: "SAFe",
    // logo: require("../img/1.png"),
    text: 'How do you scaled agile?'
  },
  {
    id: "3",
    heading: "DevOps",
    // logo: require("../img/1.png"),
    text: 'How Dev and Ops teams align?'
  },
  {
    id: "4",
    heading: "Design Thinking",
    // logo: require("../img/1.png"),
    text: 'How to move through DVF maze? '
  }

];